/* Global container */
.maintenance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 10px; 
  background-color: #bebdbd6c;
}


.maintenance-image img {
  max-width: 80%;
  margin-bottom: 20px;
  margin-top: 20px;
}


.maintenance-content h1 {
  font-size: 35px;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: center;
}

.maintenance-content p {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
}



.back-to-top {
  display: none;
}


.maintenance-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 16px;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #007bff;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.maintenance-button:hover {
  background-color: #208536;
}

@media (max-width: 768px) {
  .maintenance-container {
      flex-direction: column; 
      height: auto; 
  }

  .maintenance-image img {
      max-width: 100%; 
  }

  .maintenance-content h1 {
      font-size: 28px; 
  }

  .maintenance-content p {
      font-size: 14px; 
  }

  .maintenance-button {
      bottom: 10px;
      right: 10px;
      font-size: 14px;
      padding: 8px 12px; 
  }
}

@media (max-width: 480px) {
  .maintenance-content h1 {
      font-size: 24px; 
  }

  .maintenance-content p {
      font-size: 12px;
      line-height: 1.4; 
  }

  .maintenance-button {
      font-size: 12px;
      padding: 6px 10px;
  }
  .maintenance-image img{
    margin-top: 50%;
  }
}
