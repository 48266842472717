
.popup-alert.success {
    background-color: #4caf50; 
  }
  
  .popup-alert.error {
    background-color: #f44336; 
  }
  
  .popup-alert.warning {
    background-color: #ff9800; 
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 0.9;
      transform: translateY(0);
    }
  }
  