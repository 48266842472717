.feature-card {
    background-color: #E9F3EB; /* Couleur de fond légèrement verte */
    border: none; /* Supprime la bordure */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre subtile */
    
  }
  
  .icon {
    font-size: 2em; 
    color: #208536;
  }
  
  .feature-title {
    font-weight: bold;
    margin-top: 10px;
    color: #333;
  }
  
  .feature-description {
    color: #666;
    font-size: 0.9em;
  }
  