

/*------ debut price -----*/
.container h2 {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .pricing-card {
    background-color: #f7f7f7;
    /* border-radius: 10px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 20px;
  }
  .pricing-card hr{
    display: none;
  }
  .highlighted-card {
    border-top: 10px solid #ffd700;
    background-color: #208536;
    color: #f7f7f7;
  }
  .highlighted-card hr {
    border-top: 2px solid #ffd700;
    display: block;
  }
  .pricing-card  .row{
    margin-bottom: -5%;
  }
  .pricing-card  .row .col-4{
    margin-top: -5%;
    margin-left:-10%;
  }
  .card-title {
    font-size: 2rem;
  }
  
  .card-text {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }
  
  .price {
    font-size: 2.5rem;
    font-weight: bold;
    color: #208536;
  }
  .price-info {
    font-size: 0.9rem;
    color: #208536;
  }
  .highlighted-card .price-info {
    color: #f7f7f7;
  }
  .highlighted-card .price {
    color:#f7f7f7 ;
  }
  
  
  .discount-badge {
    background-color: #ffd700;
    color: black;
    padding: 5px;
    
  }
  
  .btn-outline-primary {
    background-color: #208536;
    color: #f7f7f7;
    border: none;
    font-family: 'Rubik';
    padding: 2% 10%;
    margin-bottom: 5%;
  }
  .btn-outline-primary:hover {
    background-color: #ffd700;
    color: black;
  }
  .highlighted-card .btn-outline-primary {
    background-color: #ffd700;
    color: black;
  }
  .highlighted-card .btn-outline-primary:hover {
    background-color: rgb(93, 79, 244);
    color: #f7f7f7;
  }
 
  
  .feature-list {
    list-style: none;
    padding: 0;
    text-align: left;
  }
  
  .feature-list li {
    padding-left: 1.5rem;
    position: relative;
    margin-bottom: 1.5rem;
  }
  
  .feature-list li:before {
    content: "✔";
    color: #f7f7f7;
    position: absolute;
    left: 0;
    background-color: black;
    border-radius: 50%;
    padding: 2px;
    width: 20px;
    height: 20px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .highlighted-card .feature-list li:before {
   
    color: #208536;
    background-color: #f7f7f7;
  }
  
  .dropdown select {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
  }
/*------- fin du  style price ----*/
.div-logo{
 background-color: white;
 padding: 0%;
}
.div-nav{
  background-color: #9999994e;
}